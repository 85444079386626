.accountDrawer {
  :global {
    .ant-drawer-body {
      padding: 0px;
      overflow: hidden;
      overflow-y: auto;
    }
    .vendorForm {
      line-height: 1;
    }
  }
}

.FormComponent {
  :global {
    .ant-select-lg {
      height: 37px;
      .ant-select-selector {
        font-size: 0.875rem;
      }
    }

    .ant-select-selection-item {
      color: #000000e0;
    }

    .ant-select-selector {
      border-radius: 4px !important;
    }

    .ant-input-lg {
      border-radius: 4px;
      font-size: 0.875rem;
    }

    .ant-form-item {
      margin-bottom: 0px;

      .ant-form-item-label {
        label {
          color: #a3a9b1 !important;
          font-size: 13px;
        }
      }
    }

    .titleMendetory {
      font-weight: 300;
      color: #ff4d4f;
      font-size: 0.875rem;
    }

    .titleField {
      font-weight: 300;
      font-size: 0.875rem;
      color: rgb(73, 73, 73);
    }
    .itemSac {
      .ant-flex .ant-form-item {
        width: 50%;
      }
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-table-cell {
      padding: 10px !important;
    }

    .editable-cell-value-wrap {
      padding: 4px;
    }
  }
}
