@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url(./default.scss);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../assets/main-bg.png) !important;
  // background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    border-radius: 20px;
  }
}

* {
  font-weight: 400;
  letter-spacing: 0.18px;
  // color: #fff;
  box-sizing: border-box;
}

h3 {
  margin: 0;
}

.container {
  padding: 0 25px;
  width: 100%;
}

:where(.css-dev-only-do-not-override-1qamnpy).ant-layout-header {
  line-height: unset;
}

.ant-form-item-label {
  font-weight: 600;
}

.custom-header {
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  height: auto;
}

.header-tab {
  .ant-segmented-group {
    .ant-segmented-thumb {
      border-radius: 50px;
    }

    .ant-segmented-item {
      border-radius: 50px;
      padding: 7px 5px;
      font-size: 14px;
      line-height: 1;
      margin: 0px 5px;
    }
  }
}

.ant-dropdown-menu-title-content {
  color: #6f7994;
}

.filter-btn {
  padding: 7.5px 20px !important;
  border-radius: 50px;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: #6f7994;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-input {
  border: none;
  border-radius: 6px;
  height: auto;
  max-width: 700px;

  .ant-input {
    border-right: 1px solid transparent;
    padding: 7px 11px;
  }

  .ant-input-group-addon {
    background-color: white;

    svg {
      fill: #b1b6bb;
    }
  }
}

.ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-block: 0px !important;
}

.custom-modal {
  .ant-modal-close {
    top: 16px;
    right: 24px;
  }

  .ant-modal-header {
    border-bottom: 1px solid #dbdfe9;
    padding: 16px 24px;
    margin-bottom: 0px;

    .ant-modal-title {
      font-size: 20px;
      color: #1e232a;
    }
  }

  .ant-modal-content {
    padding: 0px;
  }

  .ant-modal-body {
    padding: 16px 24px;
  }

  .ant-modal-footer {
    border-top: 1px solid #dbdfe9;
    margin-top: 0px;
    padding: 16px 24px;
  }
}

.custom-inputbox-dropdown {
  .ant-input-group-addon {
    width: 180px !important;
    background-color: transparent !important;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      background-color: #dbdfe9;
      width: 1px;
      right: 0;
      top: 15px;
      bottom: 15px;
    }
  }

  .ant-input {
    border-left-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px;

    &:focus,
    &:hover {
      border-color: #d9d9d9;
      border-left-color: transparent;
      box-shadow: none;
    }

    &:where(.css-dev-only-do-not-override-uxqdjd).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
      border-left-color: transparent;
      box-shadow: none;
    }
  }
}

.assign-dropdown {
  height: auto;

  .ant-select-selection-item {
    height: auto !important;
    padding: 3px;

    .ant-select-selection-item-content {
      font-size: 14px;
    }
  }
}

.task-drawer {
  .ant-drawer-header {
    display: none;
  }

  .ant-drawer-body {
    padding: 0px;

    .task-drawer-body {
      padding: 20px 24px 30px 24px;

      .task-title {
        padding: 16px 12px;
        background-color: #f8f8f8;
        border-radius: 8px;
        margin-bottom: 30px;

        .icon {
          height: 28px;
          width: 28px;
          margin-right: 12px;
        }

        .ant-typography {
          font-size: 20px;
          color: #1e232a;
          font-weight: 600;
          letter-spacing: 0.25px;
        }
      }

      .label-title {
        font-size: 18px;
        color: #6f7994;
        letter-spacing: 0.15px;
        font-weight: 500;
      }
    }

    .sub-task {
      padding: 13px;

      .ant-checkbox .ant-checkbox-inner {
        height: 18px;
        width: 18px;
        border-radius: 50%;
      }

      .task-input {
        .ant-input-group-addon {
          background-color: transparent;
        }

        .ant-input {
          padding: 12px 11px 12px 0px;
          border-left: none;
          border-right: none;
          box-shadow: none;

          &.ant-input-status-error {
            box-shadow: none;
            border-top-color: #ff4d4f;
            border-bottom-color: #ff4d4f;
          }

          &:hover,
          &:focus-within {
            border-color: #d9d9d9;

            &.ant-input-status-error {
              box-shadow: none;
              border-top-color: #ff4d4f;
              border-bottom-color: #ff4d4f;
            }
          }
        }

        .ant-radio-wrapper {
          margin-inline-end: 0px;
        }
      }
    }

    .ant-form-item-label {
      label {
        font-size: 18px !important;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #1e232a;
        font-weight: 500;
      }
    }

    .task-amount-input {
      .ant-input-number-input {
        padding: 13px 11px;
        font-size: 18px;
        line-height: 20px;
        height: auto;
        width: 100%;
        background-color: #f8f8f8;
        color: #6f7994;
      }

      .ant-input-number-handler-wrap {
        display: none;
      }
    }

    .task-checkbox {
      font-size: 16px;
      font-weight: 500;
      .ant-checkbox-inner {
        height: 20px;
        width: 20px;
        border-width: 2px;
      }
      .ant-checkbox-wrapper {
        color: #6f7994;
      }
    }

    .paymentBtn {
      color: #1bc5bd;

      &:hover {
        color: #1bc5bd;
      }
    }

    textarea {
      background-color: #f8f8f8;
      border: none;
      box-shadow: none;
      font-size: 18px;

      &:focus,
      &:hover {
        box-shadow: none;
        background-color: #f8f8f8;
      }

      &::placeholder {
        color: "#6F7994";
      }
    }
  }

  .task-drawer-footer {
    padding: 16px 24px;
    border-top: 1px solid #dfe1e5;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffff;
  }
}

.data-table {
  .ant-table {
    border-radius: 10px;
    table {
      color: #6f7994;
      .ant-checkbox {
        .ant-checkbox-inner {
          border-color: rgba(111, 121, 148, 1);
        }
        &:hover .ant-checkbox-inner {
          border-color: rgba(111, 121, 148, 1) !important;
        }
      }
      th.ant-table-cell {
        color: rgba(111, 121, 148, 1) !important;
        font-weight: 400;
        padding: 11px 16px;

        &::before {
          content: unset !important;
        }
      }

      thead tr th {
        border-top: 1px solid #dbdfe9;
        border-bottom: 1px solid #dbdfe9;

        &:first-child {
          border-left: 1px solid #dbdfe9;
        }

        &:last-child {
          border-right: 1px solid #dbdfe9;
        }
      }

      tbody {
        .ant-table-expanded-row {
          display: none !important;
        }
        .ant-table-row-level-1 {
          background-color: #f8f8f8;
        }
        tr {
          td {
            border-bottom: 1px solid #dbdfe9;
            padding: 0px 16px;
            height: 46px;
            &:first-child {
              border-left: 1px solid #dbdfe9;
            }

            &:last-child {
              border-right: 1px solid #dbdfe9;
            }
          }

          &.ant-table-row:last-child {
            td {
              &:first-child {
                border-bottom-left-radius: 10px;
              }

              &:last-child {
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.status-dropdown {
  .ant-select-selector {
    border-radius: 30px;

    span.dot {
      min-width: 8px;
      max-width: 8px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: block;
      margin-right: 5px;

      &.active {
        background-color: #50cd89;
      }

      &.deactive {
        background-color: #ff4d49;
      }
    }
  }
}

.delete-btn {
  border: none;
  background-color: #f8285a1a;
  color: #f8285a !important;

  &:hover {
    background-color: rgb(248 40 90 / 16%) !important;
  }
}

.dptbgcolor {
  height: 20px;
  width: 20px;
  margin: 0 auto;
}

.dpticon {
  height: 20px;
  width: 20px;
  background-size: cover;
  overflow: hidden;
  background-position: center;
}

.ant-menu-submenu-popup > .ant-menu {
  .ant-menu-title-content {
    color: #6f7994 !important;
  }

  .ant-menu-submenu-arrow {
    color: #6f7994 !important;
  }
}

.ant-select-dropdown {
  background-color: rgb(255, 255, 255) !important;
}

.ant-select-item-option-content {
  color: #6f7994 !important;
}

.ant-select-item-option-selected {
  background-color: #6f799427 !important;
}

.ant-select-item-option-active {
  background-color: #6f799410 !important;
}

.horizontal-collapse-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0px;
}

.toolbar {
  margin-right: 16px;
  /* Adjust margin as needed */
}

.collapse-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 200px;
  /* Initial height of the collapsed content */
}

.collapse-content.collapsed {
  max-height: 0;
}

.style_taskCollaps__CCZY- {
  margin-bottom: 0px !important;
}

.assignTo {
  .ant-popover-inner {
    margin: 0 10px;
    padding: 0 10px;
    width: 300px;

    .ant-popover-title {
      width: 100%;
      padding: 15px 0;
      border-bottom: 1px solid rgba(82, 92, 105, 0.1);
      margin-bottom: 0px;

      .ant-input-group > .ant-input:first-child {
        border-radius: 6px !important;
      }

      .ant-btn {
        background-color: #2fc6f6;
        box-shadow: none;

        span {
          font-weight: 400;
        }
      }
    }

    .ant-checkbox-wrapper {
      &:hover .ant-checkbox-inner {
        border-color: #56d1e0;
      }

      &:hover .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: #56d1e0;
        }
      }
    }

    .ant-popover-inner-content {
      min-height: auto;
      max-height: 400px;
      overflow-y: scroll;

      .ant-list {
        margin: 8px 0;

        .ant-list-item-meta-title > a:hover {
          color: rgba(0, 0, 0, 0.88);
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #56d1e0;
        border-color: #56d1e0;
      }
    }

    .ant-list-split .ant-list-item {
      border-block-end: none;
    }

    .ant-list-item-meta-title {
      margin: 0px !important;
    }

    .ant-input-group-addon {
      display: none;
    }
  }
}

.assigneePopover {
  .ant-popover-inner {
    min-width: 150px !important;
    padding: 10px !important;
    max-width: auto;
  }
}

.taskType {
  .ant-select-item-option-content {
    span {
      line-height: 1;
      display: inline-flex;
    }
  }

  .ant-flex {
    line-height: 1;
  }
}
.clientSelectBox {
  height: auto !important;
  .ant-select-selector {
    height: auto !important;
  }
  .ant-select-selection-placeholder {
    color: #2067b0;
    font-weight: 500;
    font-size: 14px;
  }
  .ant-select-selection-item {
    font-weight: 500;
    font-size: 14px;
    & > div {
      display: flex;
      flex-direction: column;
    }
    span {
      font-size: 12px;
      line-height: 1;
    }
  }
}

.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove {
  display: none !important;
}

.ant-checkbox:hover {
  .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
  }
}

.ant-checkbox .ant-checkbox-inner {
  &:hover {
    border-color: #d9d9d9;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #30bfee !important;
  border: none;
}
.ant-checkbox-indeterminate {
  .ant-checkbox-inner::after {
    background-color: #30bfee !important;
  }
}

.statusSelect {
  width: 200px !important;

  .ant-select-item-option-content {
    font-size: 12px !important;
  }
}
.contactTag {
  width: 100px !important;
}

.menuDropdown {
  .ant-dropdown-menu-submenu-arrow-icon {
    color: #ff0000b0 !important;
  }
}

.AsssignnePopover {
  .ant-popover-inner {
    padding: 8px;

    .ant-popover-inner-content {
      .ant-avatar {
        margin-left: 0px !important;
        margin: 0 3px;
      }
    }
  }

  span {
    .ant-avatar-string {
      font-size: 14px;
      color: #ffffffd6;
    }
  }
}

.ant-image-preview-mask,
.ant-image-preview-wrap {
  z-index: 1001 !important;
}

.userLayout {
  margin-right: 50px;
  .layoutSideBarOpen {
    margin-left: 80px;
  }

  .layoutSideBarClose {
    margin-left: 234px;
  }

  @media (max-width: 1550px) and (min-width: 1200px) {
    .layoutSideBarOpen {
      margin-left: 60px;
    }

    .container {
      padding: 0 15px;
    }
  }

  @media (max-width: 1550px) and (min-width: 1400px) {
    .layoutSideBarClose {
      margin-left: 200px;
    }
  }

  @media (max-width: 1400px) and (min-width: 1200px) {
    .layoutSideBarClose {
      margin-left: 180px;
    }
  }
}
.PhoneInput {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  padding: 7px 11px;
  background-color: white;

  .PhoneInputInput {
    width: 100%;
    height: 24px;
    font-size: 14px;
    line-height: 1;
    outline: none;
    border: none;
    font-weight: 300;

    &::placeholder {
      text-overflow: ellipsis;
      color: #bfbfbf;
    }
  }
}
.passport-dropbox {
  .ant-upload {
    width: 100% !important;
    height: 170px !important;
  }
  .ant-upload-list-item-container {
    width: 100% !important;
    height: 400px !important;
  }
  .ant-upload-list-item-actions > a {
    display: none;
  }
  .form-upload {
    .ant-upload-list-item-image {
      object-fit: fill !important;
    }
  }
}
.passport-dropbox-md {
  .ant-upload {
    width: 100% !important;
    height: 150px !important;
  }
  .ant-upload-select {
    background-color: transparent !important;
  }
  .ant-upload-list-item-container {
    width: 100% !important;
    // height: 150px !important;
  }
  .ant-upload-list-item-actions > a {
    display: none;
  }
  .form-upload {
    .ant-upload-list-item-image {
      object-fit: fill !important;
    }
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
}
.custom-file-list-item {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 100%;
  .custom-file-list-block {
    padding: 8px;
    position: relative;
    img {
      width: 100%;
      height: 84px;
      object-fit: cover;
      display: block;
    }
    .doc-block {
      width: 100%;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.45);
      border-radius: 7px;
      transition: 300ms all;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      color: #fff;
      opacity: 0;
      .anticon {
        font-size: 16px;
        cursor: pointer;
      }
      .anticon-eye {
        position: absolute;
        // top: 40%;
        left: 20%;
        top: 39%;
      }
      .anticon-delete {
        position: absolute;
        right: 20%;
        top: 39%;
      }
      a {
        color: #fff;
      }
    }
  }
  .download-block {
    display: inline-flex;
    position: absolute;
    top: 39%;
    left: 41%;
    border-radius: 50%;
    text-align: center;
    opacity: 0;
    cursor: pointer;
    transition: 300ms all;
    svg {
      fill: white;
      font-size: 18px;
      color: white;
    }
  }
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
  &:hover {
    .download-block {
      opacity: 1;
    }
  }
}
.passport-image-list-item {
  padding: 4px;
  border: 1px solid #d9d9d9;
  border-top-color: transparent;
}
.passport-back-list {
  display: none;
}
.close-bar {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  align-items: end;
  transition: 400ms all;
}
.ant-drawer-mask {
  &.hide-mask {
    background: transparent;
  }
  &.show-mask {
    background: rgba(0, 0, 0, 0.45);
  }
}

.ItemModel {
  .ant-modal-content {
    padding: 0px;
    padding-bottom: 12px;
  }
  .header {
    padding: 12px 24px;
    .title {
        font-size: 1.425rem;
    }
  }
}
