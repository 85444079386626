.footer {
    background-color: transparent;
    padding: 12px 25px;
    color: #fff;

    :global {
        .ant-btn {
            span {
                color: #fff;
            }
        }

        .anticon svg {
            fill: #fff;
        }

        .ant-typography {
            color: #fff;
            font-weight: 400;
        }
    }
}

.footerSelect {
    :global {
        .ant-select-selector {
            background: rgba(255, 255, 255, 0.08) !important;
            border: 1px solid rgba(255, 255, 255, 0.4) !important;
            color: #fff !important;
        }

        .ant-select-open span {
            color: rgb(255, 255, 255) !important;
        }

        .ant-select .ant-select-arrow .anticon > svg {
            fill: rgb(255, 255, 255) !important;
        }
    }
}

.rightFooter {
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 5px 9px;
    background-color: rgba(243, 243, 243, 0.72);
    transition: 0.3s all !important;
    position: fixed;
    bottom: 12px;
    right: 90px;
    backdrop-filter: blur(9px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    z-index: 1001;

    :global {
        .menu-content {
            display: flex;
            align-items: center;
            max-width: 0;
            max-height: 28px;
            opacity: 0;
            overflow: hidden;
            transition: max-width 0.5s ease, opacity 0.5s ease;
            right: 40px;
        }
        .menu-content.open {
            max-width: 500px;
            opacity: 1;
        }

        .arrow-icon {
            display: inline-flex;
            padding-right: 5px;
        }

        .minCloseBtn {
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .anticon svg {
            fill: #6f7994;
            height: 10px;
            line-height: 1;
        }

        .ant-btn-default {
            border: none;
            background: none !important;
        }

        .ant-btn:focus-visible {
            outline: none;
        }
    }
}

.minimizeTag {
    padding: 4px 10px;
    border-radius: 6px;
    border: none;
    color: #828b95;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.themeModel {
    :global {
        .ant-modal-title {
            color: rgb(111, 121, 148);
        }
        .image-container {
            position: relative;
            transition: transform 0.3s ease;
        }

        .image-container:hover .hover-overlay {
            opacity: 1;
            transform: translateY(0);
        }

        .hover-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 30px;
            background-color: rgba(0,0,0,.2);
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(100%);
            transition: transform 0.2s ease, opacity 0.1s ease;
            opacity: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .Modelfooter {
            .custom {
                font-size: 13px;
                color: rgb(111, 121, 148);
                cursor: pointer;
            }
            .ant-btn {
                background-color: #bbed21;
                font-size: 12px;
                padding: 10px 15px;
                color: rgb(111, 121, 148);
                box-shadow: none;
                span {
                    font-weight: 600;
                }
                &:hover {
                    background-color: #d2f95f;
                    color: rgb(111, 121, 148);
                }
            }
            .cancel {
                background-color: transparent;
                &:hover {
                    background-color: transparent;
                    color: rgb(111, 121, 148);
                }
            }
        }
    }
}
