.drawerSelect {
    .ant-select-selector {
        background: transparent !important;
        border: 1px solid rgba(198, 205, 211, 1) !important;
    }

    .ant-select-selection-item {
        color: #6f7994 !important;
    }
}

.taskDrawer {
    background-color: #eef2f4 !important;
    padding-right: 50px;
    position: relative;
    overflow: visible !important;

    :global {
        * {
            // color: #333333;
            font-weight: 400;
            letter-spacing: 0.3px;
        }

        .close-label {
            padding: 7px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            background-color: #30bfee;
            cursor: pointer;
            margin-bottom: 18px;
            transition: 300ms all;

            .anticon {
                padding: 3px;
                border: 2px solid transparent;
                transition: all 0.3s;

                svg {
                    fill: #fff;
                }
            }

            span {
                padding-left: 5px;
                font-weight: 400;
                color: #fff;
                font-size: 11px;
            }

            &:hover {
                background-color: #ee5454;

                .anticon {
                    border: 2px solid rgba(255, 234, 234, 0.95);
                    border-radius: 50%;
                }
            }
        }

        .task-spin {
            height: calc(100vh - 65px) !important;
            max-height: none !important;
        }

        .minimize-label {
            padding: 7px;
            padding-left: 15px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            background-color: #d9dcdf;
            cursor: pointer;
            color: #fff;
            display: inline-flex;
            margin-bottom: 18px;

            svg {
                fill: #525c69;
                opacity: 0.5;
                transition: all 0.5s;
            }

            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }

        .sync-label {
            padding: 10px;
            padding-left: 15px;
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            background-color: #d9dcdf;
            color: #525c69;
            font-size: 13px;
            transition: all 0.4s;

            div {
                font-weight: 400;
                line-height: 1;
            }

            &.saved {
                background-color: #52c41a;
            }
        }

        .drawer-header {
            padding: 23px 0px 23px 12px;

            // .ant-tag.ant-tag-orange {
            //   font-size: 14px;
            //   padding: 5px 6px;
            //   line-height: 1;
            // }
        }

        .ant-drawer-body {
            padding: 0px 15px 15px;
        }

        .drawer-title {
            font-size: 26px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.3px;
            color: rgb(51, 51, 51);
            margin-right: 20px;
        }

        .ant-select-dropdown {
            width: 300px !important;
        }

        .template-dropdown {
            .ant-select-selector {
                background-color: transparent;
                border-color: #c6cdd3;

                &:hover {
                    border-color: #b9bdc0 !important;
                }
            }

            .ant-select-selection-placeholder,
            .ant-select-selection-item {
                font-size: 12px;
                color: #6f7994;
            }

            .ant-select-selection-item-content {
                display: inline-flex !important;
            }

            .ant-flex-align-center {
                span {
                    display: inline-flex;
                    line-height: 1;
                }
            }

            .anticon>svg {
                fill: #6f7994;
            }
        }

        .createTaskWrapper {
            margin-bottom: 16px;

            .ant-card-body {
                padding: 15px;

                .title-wrapper {
                    border-bottom: 1px solid #edeef0;
                    padding-bottom: 0px;

                    .ant-input {
                        padding: 0px 0px 10px 9px;
                    }

                    .ant-typography,
                    input {
                        font-size: 24px;

                        &::placeholder {
                            color: #aaaaaa;
                        }
                    }
                }

                .clientContact {
                    font-size: 18px !important;
                }

                .taskField {
                    background-color: #f8f9fa;
                    border-radius: 10px;
                    padding: 15px 20px 15px 11px;

                    .ant-picker-input>input {
                        font-size: 13px;
                    }
                }
            }

            .assigneeField {
                .ant-checkbox {
                    display: none;
                }

                .ant-checkbox+span {
                    padding-inline-start: 0px !important;
                    padding-inline-end: 0px !important;
                }
            }

            .stepper {
                position: relative;
                background-color: #f6f6f6;
                border-radius: 8px;
                padding: 18px;
                display: flex;
                justify-content: center;
                margin-bottom: 40px;
            }
        }

        .ant-card-body {
            padding: 15px;
        }

        .title-wrapper {
            border-bottom: 1px solid #edeef0;
            padding-bottom: 12px;

            .ant-input {
                padding: 0px 0px 12px 9px;
            }

            .ant-typography,
            input {
                font-size: 24px;

                &::placeholder {
                    color: #aaaaaa;
                }
            }
        }

        .ant-form-item {
            margin-bottom: 0;
        }

        .ant-drawer-footer {
            padding: 0px !important;
            border-top: 0px !important;
        }

        .CreateTaskFooter {
            padding: 16px 20px;
            background-color: white;
            box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.1);
        }

        .addTaskbtn {
            background-color: #bbed21;
            padding: 9px 10px 9px 14px;
            border: none;
            transition: 0.3s all;

            span {
                font-size: 13px !important;
                font-weight: 400;
                color: #6f7994;
            }

            &:hover {
                background: #d2f95f !important;
            }
        }

        .createAnotherTaskbtn {
            border: 1px solid #c6cdd3;
            padding: 11px 15px;

            span {
                font-size: 13px !important;
                font-weight: 400;
                color: #6f7994;
            }

            &:hover {
                background: #cfd4d8 !important;
                box-shadow: 0 0 5px #cfd4d8;
            }
        }

        .cancalbtn {
            background: none;
            border: none;
            box-shadow: none;

            span {
                font-size: 13px !important;
                font-weight: 400;
                color: #6f7994;

                &:hover {
                    color: #333333;
                }
            }
        }

        .ant-picker-outlined:hover {
            border-color: #d9d9d9;
        }

        .ant-picker-outlined:focus {
            box-shadow: none;
        }

        .ant-select:hover {
            border-color: #d9d9d9 !important;
        }

        .ant-input-lg {
            padding: 7px 0;
        }
    }
}

.notes {
    :global {
        .ant-input {
            border: none;
            margin: 5px 0 10px;
            padding: 7px 9px;
            resize: none;
        }

        .ant-input-outlined:focus {
            box-shadow: none;
        }
    }
}

.uploadContainer {
    background-color: #f8f9fa;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px 15px;

    :global {
        .ant-divider {
            border-block-color: rgba(82, 92, 105, 0.1) !important;
        }

        .ant-upload-list-picture-card {
            img {
                // object-fit: none !important;
                border-radius: 8px;
            }

            .ant-upload-list-item::before {
                border-radius: 8px;
            }
        }
    }
}

.showPreview {
    :global {
        .ant-upload-drag {
            margin-bottom: 15px;
        }

        .ant-upload-list-picture-card {
            padding-top: 15px;
            border-top: 1px solid #ccd0d55d;
        }
    }
}

.checkList {
    :global {
        .ant-typography {
            color: #1e1e1e96;
        }

        .ant-collapse-expand-icon {
            height: auto !important;
        }

        .tagList:first-child {
            .ant-tag {
                border-top: 1px solid #d9d9d96b !important;
                margin-top: 10px;
            }
        }

        .ant-collapse-header {
            padding: 0px !important;
            align-items: center !important;
        }

        .ant-collapse-content-box {
            padding: 0px !important;
        }

        .ant-input-group-addon {
            background: none;
            border: none;
        }

        .ant-radio-wrapper {
            margin-inline-end: 0px;
            padding: 0px;
        }

        .ant-tag.ant-tag-blue {
            background: none;
        }

        .ant-checkbox:hover {
            .ant-checkbox-inner {
                border-color: #d9d9d9 !important;
            }
        }

        .ant-checkbox .ant-checkbox-inner {
            border-radius: 50%;
            height: 20px;
            width: 20px;
            background-color: transparent;

            &:hover {
                border-color: #d9d9d9;
            }
        }

        .subtask-input-item {
            .ant-form-item-control {
                min-height: auto !important;
            }
        }

        .ant-input-lg {
            padding-left: 10px !important;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #30bfee !important;
            border: none;
        }

        .ant-tag {
            border: none;
            width: 100%;
            border-bottom: 1px solid #d9d9d96b !important;
            padding: 12px 0;
            font-size: 15px;
            color: #949494;
            border-radius: 0;
            padding-inline: 0px;

            &:hover>.deleteIcon {
                opacity: 1;
            }
        }

        .ant-input-group-addon {
            padding: 0;
        }

        .ant-input-group-addon:last-child {
            position: absolute;
            top: 12px;
            right: 25px;
            z-index: 1;
        }

        .ant-btn {
            span {
                font-size: 13px;
                font-weight: 400;
            }

            span:nth-child(2) {
                border-bottom: 1px dashed #80868e;
                border-radius: 0px;
            }

            &:focus-visible {
                outline: none !important;
            }
        }

        .deleteIcon {
            padding-right: 10px;
            opacity: 0;
            transition: 0.3s all;
        }

        .sub-task-item:hover {
            .deleteIcon {
                opacity: 1;
            }
        }

        .ant-input-outlined {
            &:focus {
                border-color: #d9d9d9;
                box-shadow: none;
            }

            &:hover {
                border-color: #d9d9d9;
            }
        }

        .ant-progress-bg {
            background: #30bfee;
        }

        .ant-progress-text {
            display: none !important;
        }
    }
}

.subtaskTitle {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    color: #525c69 !important;
    font-weight: 400 !important;
    line-height: 32px !important;
    inset-inline-start: 0px !important;
    margin-top: 0px !important;

    textarea {
        font-weight: 400 !important;
        font-size: 14px !important;
    }
}

.customeAssigneeInput {
    padding: 5px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 8px;
    width: 420px;
    min-height: 40px;
    display: flex;
    align-items: center;

    :global {
        .label-tag {
            color: #2067b0;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
        }

        .ant-tag {
            margin-inline-end: 0px;
            background-color: #bcedfc;
            border-color: #bcedfc;
            padding-top: 3px;
            padding-bottom: 3px;
            color: #1f6ab5;
            font-weight: 400;
            font-size: 13px;
        }
    }
}

.taskDetailDrawer {
    :global {
        .detailInquiryType {
            border: 1px solid #c6cdd3;
            color: #6f7994;
        }

        .ant-divider {
            border-block-start-color: #eef2f4 !important;
        }

        .taskDetailNote {
            min-height: 80px;
            align-items: start;

            .content {
                font-size: 14.5px;
                color: black;
            }
        }

        .detailBtn {
            background-color: #bbed21;
            padding: 20px 20px;
            border: none;
            transition: 0.3s all;
            border-radius: 4px;

            span {
                font-size: 13px !important;
                font-weight: 400;
                color: #525c69;
            }

            &:hover {
                background: #d2f95f !important;
            }
        }
    }
}

.imageView {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 10px;

    :global {
        .ant-upload-list-item-image {
            object-fit: cover !important;
        }
    }
}

.taskActivityTab {
    :global {
        .ant-tabs-nav {
            margin-bottom: 0px;
        }

        .ant-tabs-content-holder {
            background-color: #fff;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            border-color: #fff !important;

            .ant-tabs-tab-btn {
                color: #5c6470;
            }
        }
    }
}

.taskDetailSideBar {
    min-height: auto;
    max-height: 80%;
    min-width: 310px;

    :global {
        .ant-card-body {
            padding: 0px;
        }

        .detailStatus {
            background-color: #56d1e0;
            padding: 20px 15px;
            border-radius: 10px 10px 0px 0px;

            .ant-typography {
                font-weight: 600;
                font-size: 14px;
            }
        }

        .details {
            padding: 10px 0;
            margin-bottom: 3px;
        }

        .title {
            width: 100px !important;
            color: rgb(82, 92, 105);
            font-size: 14px;
            font-weight: 400;
        }

        .data {
            color: rgb(100, 104, 110);
        }

        .ant-btn>span {
            color: #858c96;
            font-size: 13px;
        }

        .ant-picker-large .ant-picker-input>input {
            font-size: 14px;
            color: rgb(100, 104, 110);
        }

        .status {
            .ant-select-outlined {
                &:hover .ant-select-selector {
                    border-color: #3bc8f5 !important;
                    box-shadow: none !important;
                    padding: 2px 0;
                }

                .ant-select-selector {
                    border-color: #3bc8f5;
                    background-color: #3bc8f52e;

                    .ant-select-selection-item {
                        text-align: center;
                        color: #525c69;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    @media (max-width: 1400px) and (min-width: 1200px) {
        min-width: 270px;
    }
}

.checkListWrapper {
    position: relative;

    .TaskDescription {
        background-color: #f8f9fa;
        border-radius: 10px;
        color: #fff;
        margin-bottom: 10px;
        z-index: 5;
        position: relative;
        padding: 10px 20px;

        :global {
            .taskDetails {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }

                .label {
                    width: 100px;
                    color: #5e6675;
                }

                .ant-select-outlined.ant-select-multiple .ant-select-selection-item {
                    background-color: #bcedfc;
                    display: flex;
                    align-items: center;
                    padding-inline-start: 0px !important;

                    .ant-checkbox {
                        display: none !important;
                    }

                    .ant-select-selection-item-content {
                        color: #1f6ab5;
                        font-weight: 400;
                        font-size: 14px;
                    }
                }

                .ant-picker-large .ant-picker-input>input {
                    font-size: 14px;
                }

                .ant-btn-default {
                    span {
                        &:focus-visible {
                            outline: none;
                        }
                    }

                    &:hover {
                        color: #5e6675;
                    }

                    &:focus-visible {
                        outline: none;
                    }

                    &:active {
                        color: #5e6675;
                        border-color: transparent;
                    }
                }
            }
        }
    }
}

.addNewPassengerModel {
    :global {
        .ant-modal-content {
            padding: 0px;
        }

        .modelTitle {
            font-size: 20px;
            color: #333333;
            padding: 18px 24px;
            border-bottom: 1px solid #dfe1e5;
        }

        .formContainer {
            padding: 15px 24px;

            .fieldTitle {
                font-size: 14px;
                color: #a3a9b1;
            }

            .ant-form-item .ant-form-item-label>label {
                color: #a3a9b1;
            }

            .btn {
                .ant-btn {
                    padding: 18px 20px;
                    color: #5f617a;

                    &:first-child {
                        border: 1px solid #dfe1e5;
                    }

                    &:last-child {
                        background-color: #30bfee;
                        color: white;
                    }
                }
            }

            .ant-radio-wrapper {
                margin-inline-end: 0px;

                .ant-radio-checked .ant-radio-inner {
                    background-color: #30bfee;
                    border-color: #30bfee;
                }
            }
        }

        .ant-divider-vertical {
            height: 1.5em;
            vertical-align: bottom;
        }
    }
}