.noteView {
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 20px;
    border-radius: 8px;
    min-height: calc(100vh - 210px);
    position: relative;

    :global {
        .bulkBtn {
            .ant-btn {
                border-radius: 50%;
                padding: 20px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.06);
                }
            }
        }
    }
}

.LeftTab {
    width: 210px;

    @media (max-width: 1550px) and (min-width: 1200px) {
        width: 180px;
    }
}

.rightTab {
    width: 440px;

    @media (max-width: 1550px) and (min-width: 1200px) {
        width: 380px;
    }
}

.createNoteWrapper {
    padding: 16px;
    position: relative;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 1100px;
    margin: auto;

    :global {
        .createImage {
            &.modelImage {
                display: flex;
                gap: 8px;

                .custom-file-list-item {
                    width: 102px;
                }
            }

            .ant-upload-list-item {
                border: 1px solid rgba(0, 0, 0, 0.2) !important;
            }
        }

        .ant-form-item {
            margin-bottom: 0px;
        }

        .title {
            .ant-input {
                font-size: 16px;
                color: #333333 !important;
                padding: 0px;
                font-weight: 600;

                &::placeholder {
                    color: #838383 !important;
                }
            }
        }

        .noteField {
            .ant-form-item-control-input {
                min-height: 0px;
            }

            .ant-input {
                font-size: 16px;
                color: #333333 !important;
                padding: 0px;
                font-weight: 400;

                &::placeholder {
                    color: #979797 !important;
                }
            }

            .note-content {
                margin-bottom: 8px;

                .ant-checkbox {
                    align-self: flex-start !important;
                    margin-top: 5px;

                    .ant-checkbox-inner {
                        border: 2px solid #00000069;
                        background-color: transparent;

                        &:hover {
                            border: 2px solid #00000069;
                        }
                    }

                    &:hover {
                        .ant-checkbox-inner {
                            border: 2px solid #00000069 !important;
                        }
                    }
                }

                .ant-checkbox-checked {
                    .ant-checkbox-inner {
                        border: transparent !important;
                    }

                    &:hover {
                        .ant-checkbox-inner {
                            border: transparent !important;
                        }
                    }
                }

                .delete-item {
                    visibility: hidden;
                    cursor: pointer;
                }

                .dragHandleCreateNote {
                    visibility: hidden;
                    display: inline-flex;
                }

                .editable-div {
                    font-size: 16px;
                    line-height: 20px;
                    flex-grow: 1;
                }

                .editable-div:focus {
                    font-size: 16px;
                    outline: transparent;

                    .delete-item {
                        visibility: visible;
                        cursor: pointer;
                    }
                }
            }

            .note-content:hover {
                background-color: #c9c9c923;

                .delete-item {
                    visibility: visible;
                }

                .dragHandleCreateNote {
                    visibility: visible;
                }
            }
        }

        .footerBtn {
            .ant-btn {
                border-radius: 50%;
                transition: all 0.3s;

                &:hover {
                    background-color: #5f636828;
                }

                .ant-btn-icon {
                    display: inline-flex;
                }
            }

            svg {
                color: #333333 !important;
            }
        }

        .actionBtnWrapper {
            .cancel {
                padding: 8px 15px;
                border-radius: 6px;
                border: 1px solid #00000069;
                box-shadow: none;
                background-color: transparent;
                height: 32px;

                &:hover {
                    background-color: #dadada54;
                    border: 1px solid #00000069;
                    color: #000000de;
                }

                span {
                    font-weight: 500;
                    color: #5f617a !important;
                }
            }
        }

        .image-gallery {
            margin-bottom: 20px !important;

            .slick-track {
                .ant-image {
                    height: 400px;

                    .ant-image-img {
                        height: 100%;
                        object-fit: cover;
                        background-position: center;
                    }
                }
            }

            .createImage {
                .ant-image {
                    height: 400px;

                    .ant-image-img {
                        height: 100%;
                    }
                }
            }
        }

        .notesImage {
            margin-bottom: 7px;

            .ant-image {
                height: 120px;
                width: 120px;
                border-radius: 6px;
                padding: 7px;
                border: 1px solid rgb(155, 155, 155);
                transition: all 0.3s;

                .ant-image-img {
                    height: 100%;
                    width: 100%;
                    border-radius: 6px;
                }

                .ant-image-mask {
                    margin: 7px;
                    border-radius: 6px;
                }

                &:hover {
                    box-shadow: 0px 0px 5px rgb(197, 197, 197);
                }
            }
        }

        .pin {
            position: absolute;
            right: 1%;
            top: 8px;
            cursor: pointer;
            z-index: 10;
            line-height: 1;
            padding: 3px 4px;
            transition: all 0.3s;
            border-radius: 50%;

            &:hover {
                background-color: #5f636828;
            }
        }

        .CreateNoteTag {
            .ant-tag {
                width: fit-content;
                gap: 2px;
                background-color: #00000014;
                border-radius: 12px;

                .ant-typography {
                    font-size: 12px;
                    margin-bottom: 0px;
                    color: #3c4043;
                }
            }
        }

        .not-expanded-container {
            display: flex;
            align-items: center;

            .Create-suffix-icon {
                display: flex;
                gap: 16px;
            }
        }
    }
}

.modalCheckedCollapse {
    :global {
        .ant-collapse-item>.ant-collapse-header {
            padding-top: 0px;
        }

        .ant-collapse-content>.ant-collapse-content-box {
            padding: 0px;
        }
    }
}

.noteGirdViewContainer {
    .labelContainer {
        margin-top: 20px;
        margin-bottom: 20px;

        .labelTitle {
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: white;
            width: fit-content;
            white-space: nowrap;
        }

        .removeFilter {
            height: auto !important;
            font-size: 12px;
            padding: 2px 7px;
            border-radius: 4px;

            &:hover {
                color: black;
            }

            &:active {
                border-color: #000;
            }

            &:focus-visible {
                outline: none;
            }
        }

        .tag {
            :global {
                .ant-tag {
                    margin-inline-end: 0px;
                }
            }
        }
    }

    .filterTitle {
        :global {
            .title {
                font-size: 12px;
                color: white;
                padding: 10px;
            }

            .MuiMasonry-root>* {
                width: 240px;
            }
        }
    }

    .myMasonryGrid {
        display: flex;
        width: auto;
        flex-wrap: wrap;
        align-content: start;
    }

    .noteCard {
        position: relative;
        z-index: 10;

        .mb16 {
            margin-bottom: 16px;
        }

        :global {
            .note-card-body {
                padding: 16px;
                position: relative;
                overflow: hidden;
            }

            .note-card-head {
                min-height: auto;
                border-radius: 0;
                border-bottom: none;
            }

            .ant-checkbox {
                align-self: flex-start !important;
                margin-top: 5px;

                .ant-checkbox-inner {
                    border: 2px solid #00000069;
                    background-color: transparent;

                    &:hover {
                        border: 2px solid #00000069;
                    }
                }

                &:hover {
                    .ant-checkbox-inner {
                        border: 2px solid #00000069 !important;
                    }
                }
            }

            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    border: transparent !important;
                }

                &:hover {
                    .ant-checkbox-inner {
                        border: transparent !important;
                    }
                }
            }
        }

        .checkCircle {
            position: absolute;
            top: -8px;
            left: -8px;
            z-index: 10;
            opacity: 0;
            transition: all 0.5s;
        }

        .notesPin {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
            opacity: 0;
            transition: all 0.5s;
            cursor: pointer;
        }

        &:hover {
            box-shadow: 0px 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 3px 1px rgba(60, 64, 67, 0.149) !important;

            .checkCircle {
                opacity: 1;
            }

            .notesPin {
                opacity: 1;
            }
        }

        .cardTitle {
            margin-bottom: 0px;
            font-size: 16px;
            color: #000;
        }

        .emptyNotesTitle {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 400;
            color: rgb(95, 99, 104);
        }

        .cardCheckedCollapse {
            :global {
                .ant-collapse-item>.ant-collapse-header {
                    padding: 0px !important;
                }
            }
        }

        .cardContent {
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            &.lineClamp {
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 15;
                overflow: hidden;
                cursor: default;
            }
        }

        .remainderSection {
            box-shadow: 0px 0px 10px 0px #0000001a;
            border-radius: 4px;
            padding: 4px;
            align-items: center;
            display: flex;
            gap: 4px;
            max-width: fit-content;
            color: #333333;
        }

        .avtarContainer {
            padding: 4px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0px 2px 3px 0px #00000026;
        }

        .noteLabels {
            margin-bottom: 16px !important;

            span {
                margin-bottom: 4px;
                color: #202124;
                font-size: 12px;
                font-weight: 500;
                background-color: #00000014;
                font-size: 11px;
                font-weight: 500;
                box-shadow: inset 0 0 0 1px transparent;

                span {
                    background-color: transparent;
                }
            }
        }
    }

    :global {
        .bin-notification {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            margin-bottom: 20px;
            border-radius: 6px;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .no-data-notification {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .note-no-data-icon {
            color: white;
        }

        .no-data-text {
            color: white;
            font-size: 22px;
        }
    }
}

.noteModel {
    :global {
        .ant-modal-content {
            padding: 0px;

            .ModelWrapper {
                max-height: 785px;
                overflow-y: auto;

                .ant-form-item {
                    margin-bottom: 0px;

                    .ant-input {
                        padding: 0px;
                    }
                }

                .ModelPin {
                    position: absolute;
                    right: 15px;
                    top: 16px;
                    cursor: pointer;
                    z-index: 100;
                }

                .modelImage {
                    padding: 16px 15px 0px;
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;

                    .custom-file-list-item {
                        width: 102px;
                    }

                    // .ant-upload-list-item {
                    //     border: 1px solid rgba(0, 0, 0, 0.2);
                    // }
                }

                .Notetitle {
                    padding: 16px 15px 12px;

                    .ant-input {
                        font-size: 22px;
                    }
                }

                .NoteDecr {
                    padding: 4px 16px 20px;
                    padding-left: 0px;

                    .contain-div:hover {

                        .dragHandle,
                        .delete-item {
                            visibility: visible;
                            cursor: pointer;
                        }

                        background-color: #c9c9c923;
                    }

                    .contain-div {

                        .dragHandle,
                        .delete-item {
                            visibility: hidden;
                        }

                        .dragHandleCreateNote {
                            visibility: hidden;
                            background-color: #d93025;
                        }
                    }

                    .ant-input {
                        font-size: 16px;
                    }

                    .editable-div {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    .ant-checkbox {
                        align-self: flex-start !important;
                        margin-top: 2px;

                        .ant-checkbox-inner {
                            border: 2px solid #00000069;
                            background-color: transparent;

                            &:hover {
                                border: 2px solid #00000069;
                            }
                        }

                        &:hover {
                            .ant-checkbox-inner {
                                border: 2px solid #00000069 !important;
                            }
                        }
                    }

                    .ant-checkbox-checked {
                        .ant-checkbox-inner {
                            border: transparent !important;
                        }

                        &:hover {
                            .ant-checkbox-inner {
                                border: transparent !important;
                            }
                        }
                    }

                    .ant-collapse-item>.ant-collapse-header {
                        padding-top: 0px !important;
                    }
                }

                .noteField {
                    .ant-form-item-control-input {
                        min-height: 0px;
                    }

                    .ant-input {
                        font-size: 16px;
                        color: #333333 !important;
                        padding: 0px;
                        font-weight: 400;

                        &::placeholder {
                            color: #333333 !important;
                        }
                    }

                    .note-content {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 8px;

                        .delete-item {
                            visibility: hidden;
                            cursor: pointer;
                        }

                        .editable-div {
                            font-size: 16px;
                            line-height: 20px;
                            flex-grow: 1;
                        }

                        .editable-div:focus {
                            font-size: 16px;
                            outline: transparent;

                            .delete-item {
                                visibility: visible;
                                cursor: pointer;
                            }
                        }
                    }

                    .note-content:hover {
                        .delete-item {
                            visibility: visible;
                            cursor: pointer;
                        }
                    }
                }
            }

            .NoteTimeWrapper {
                padding: 5px 10px;

                .ant-typography {
                    color: #00000069;
                    font-size: 12px;
                }
            }
        }

        .NoteFooter {
            padding: 12px 16px;

            .ant-btn {
                border-radius: 50%;
                transition: all 0.3s;

                &:hover {
                    background-color: #5f636828;
                }

                .ant-btn-icon {
                    display: inline-flex;
                }
            }

            .closeBtn {
                padding: 8px 16px;
                border-radius: 8px;
                border: 1px solid #00000069;
                box-shadow: none;
                background-color: transparent;

                &:hover {
                    background-color: #dadada54;
                    border: 1px solid #00000069;
                    color: #000000de;
                }

                span {
                    font-weight: 500;
                }
            }
        }
    }
}

.FooterMenu>li {
    color: #000;
    padding: 0px;
}

.addLabel {
    :global {
        .ant-popover-content {
            box-shadow: 0px 2px 6px #00000057;
            border-radius: 8px;

            .ant-popover-inner {
                padding: 0px;

                .ant-popover-inner-content {
                    padding: 7px 0;

                    .ant-menu {
                        border-inline-end: none;
                        border-radius: 8px;

                        .ant-menu-item {
                            height: auto;
                            line-height: normal;
                            padding: 10px 10px 10px 15px;
                            width: auto;
                            margin-block: 0px;
                            margin-inline: 0px;
                            border-radius: 0px;

                            span {
                                font-size: 14px;
                                color: #6c7277;
                            }

                            &:hover {
                                background-color: #00000014;
                            }
                        }
                    }

                    .ant-typography {
                        padding: 5px 12px 0;
                        color: #6c7277;
                    }

                    .ant-input-group {
                        padding: 8px 12px;

                        .ant-btn-primary {
                            background-color: #2fc6f6;
                        }
                    }

                    .ant-list {
                        padding-top: 5px;

                        .ant-list-item {
                            padding: 3px 14px;
                            border-block-end: none;

                            span {
                                color: #6c7277;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.editLabel {
    :global {
        .ant-modal-content {
            border-radius: 0px;
            padding: 0;

            .labelContent {
                min-height: fit-content;
                max-height: 422px;
                overflow-y: scroll;
                padding: 15px;

                .ant-typography {
                    font-size: 16px;
                    color: #2e2e2e;
                    font-weight: 500;
                }

                .ant-input {
                    width: 80%;
                    margin: 0px 29px 0 15px;
                    color: #4b4b4b;
                    font-size: 14px;
                    font-weight: 500;
                    border-bottom: 1px solid #4b4b4b52;
                    border-radius: 0px;
                    padding: 0;
                }

                input {
                    &::placeholder {
                        color: #4b4b4b;
                    }
                }

                .error {
                    color: #d93025;
                    font-size: 12px;
                    font-style: italic;
                    padding: 10px;
                    font-weight: 300;
                }

                .labelList {
                    padding: 10px 0;

                    .ant-typography {
                        font-size: 14px;
                        color: #4b4b4b;
                        font-weight: 500;
                        margin-left: 15px !important;
                    }
                }

                svg {
                    padding: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(95, 99, 104, 0.157);
                        border-radius: 50%;
                    }
                }
            }

            .labelFooter {
                padding: 19px 10px;
                border-top: 1px solid #dadce0;
                box-shadow: 1px 0px 1px gray;

                .ant-btn {
                    padding: 8px 24px;
                    color: #4b4b4b;
                }

                .ant-btn-default {
                    box-shadow: none;

                    &:focus-visible {
                        outline: none;
                    }

                    &:active {
                        color: #4b4b4b;
                    }

                    &:hover {
                        color: #4b4b4b;
                        background-color: #5f63680a;
                    }
                }
            }
        }
    }
}

.confirmDelete {
    :global {
        .ant-modal-content {
            padding: 24px 24px 10px;

            .ant-typography {
                padding-bottom: 24px;
                color: #3c4043;
                font-weight: 300;
            }

            .deleteBtn {
                padding: 8px 24px;
                color: #e81a24;

                &:hover {
                    color: #e81a24;
                    background-color: #e81a240a;
                }
            }

            .cancelBtn {
                padding: 8px 24px;
                color: #4b4b4b;

                &:hover {
                    color: #4b4b4b;
                    background-color: #5f63680a;
                }
            }

            .ant-btn-default {
                box-shadow: none;

                &:focus-visible {
                    outline: none;
                }

                &:active {
                    border: none;
                }
            }
        }
    }
}

.reminderPopover {
    :global {
        .ant-popover-content {
            width: 260px !important;

            .ant-popover-inner {
                padding: 0px !important;
                border-radius: 4px;
                box-shadow: 0px 0px 5px rgba(60, 64, 67, 0.527);

                .ant-popover-inner-content {
                    padding: 6px 0;

                    .selectDate {
                        .ant-typography {
                            color: #202124;
                            padding: 15px;
                            font-size: 14px;
                            font-weight: 400;
                            margin-bottom: 0px;
                        }

                        .ant-btn {
                            width: 100%;
                            justify-content: space-between;
                            padding: 10px 15px;
                            margin: 5px 0;

                            .ant-typography {
                                padding: 0px;
                                color: #3c4043;
                                font-size: 13px;
                            }

                            &:hover {
                                color: #3c4043;
                            }

                            &:active {
                                border-color: transparent;
                            }
                        }

                        .ant-btn-default {
                            background-color: transparent;
                            box-shadow: none;
                            border-radius: 0px;

                            &:hover {
                                background-color: #00000014;
                            }
                        }
                    }

                    .chooseDate {
                        .title {
                            padding: 6px 12px 12px;

                            .ant-typography {
                                padding: 0px;
                            }
                        }

                        .dateTimepicker {
                            padding: 15px 15px 9px;
                            width: 100%;

                            .ant-picker {
                                border-bottom: 1px solid rgba(100, 100, 100, 0.2);
                                border-radius: 0px;
                                display: block;
                                padding: 0px;
                                padding-bottom: 5px;
                                margin-bottom: 15px;
                                color: #4b4b4b;
                            }

                            .ant-btn {
                                box-shadow: none;
                                padding: 8px 24px;
                                font-size: 14px;
                                color: #4b4b4b;
                                font-weight: 500;
                                transition: 0.3s all;

                                &:hover {
                                    background-color: #5f63680a;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.placeholder {
    transition: 300ms all;

    &.showSpace {
        padding-top: 28px;
    }
}

.cardFooter {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: -30px;
    transition: 300ms all;

    &.showBlock {
        bottom: 4px;
    }

    :global {
        .ant-btn {
            border-radius: 50%;
            transition: all 0.3s;

            &:hover {
                background: #5f636828 !important;
            }

            .ant-btn-icon {
                display: inline-flex;
            }
        }
    }
}

.tagCollection {
    margin-left: 16px;
    margin-bottom: 10px;

    :global {
        .ant-tag-borderless {
            background-color: #e0e0e0;
        }
    }
}

.selectOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    border-radius: 8px;
    transition: 300ms all;
    border: 2px solid transparent;

    &.select {
        border-color: #000;
    }
}

.selectDrawer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1002;
    padding: 0px 5px;
    transition: 200ms all;
    transform: translateY(-60px);

    .closeBtn {
        height: 60px;
        width: 60px;
        transition: all 0.4s;
        opacity: 0.54;

        &:hover {
            background-color: #5f636828;
            opacity: 1;
        }
    }

    .selectText {
        color: #3c4043;
        font-size: 22px;
        font-weight: 400;
        margin-left: 12px;
    }

    .selectButtons {
        padding: 8px;
        height: 44px;
        width: 44px;
    }

    &.show {
        transform: translateY(0px);
    }
}

.documentWrapper {
    border-radius: 6px;
    text-align: center;
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
    // background-color: #fafafacc;
    // margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.322);
    padding: 0px 7px;

    :global {
        .ant-typography {
            font-size: 12px;
            font-weight: 500;
            color: #303031;
            line-height: 1;
        }

        .document {
            padding: 6px 1px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.322);

            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.reminderLabel {

    // margin-bottom: 20px;
    :global {
        .reminder {
            width: 120px;
            background-color: #00000014;
            padding: 3px 7px;
            border-radius: 12px;
            transition: all 0.3s;

            :global {
                .ant-typography {
                    font-size: 11px;
                    margin-bottom: 0px;
                    color: #3c4043;
                }

                .close {
                    display: none;
                    cursor: pointer;
                }
            }

            &:hover {
                :global {
                    .close {
                        display: block;
                        cursor: pointer;
                    }
                }
            }
        }

        .ant-tag {
            color: #202124;
            background-color: #00000014;
            font-size: 11px;
            font-weight: 500;
            box-shadow: inset 0 0 0 1px transparent;
            border-radius: 12px;
            border: none;
            padding: 3px 7px;
            margin-inline-end: 0px;
            display: inline-flex;

            .ant-typography {
                background-color: transparent;
                color: #3c4043;
                font-size: 11px;
            }

            .close {
                cursor: pointer;
                line-height: 1;
            }
        }
    }
}

.imageUndoNotification {
    :global {
        .ant-notification-notice-message {
            font-size: 14px !important;
            color: white !important;
            padding-inline-end: 0px !important;
            margin-bottom: 0px;
        }

        .ant-notification-notice-close {
            top: 10px !important;
            width: 0px !important;

            .anticon {
                padding: 6px !important;
                border-radius: 50%;

                svg {
                    fill: white !important;
                }
            }

            &:hover {
                .anticon {
                    background: rgba(255, 255, 255, 0.039);
                }
            }
        }

        .ant-notification-notice-btn {
            span {
                color: #ffbb00 !important;
            }

            .ant-btn {
                padding: 10px 20px !important;
            }

            &:hover {
                .ant-btn {
                    background-color: rgba(255, 255, 255, 0.039);
                }
            }
        }
    }
}