.avatar {
  height: 80px;
  width: 80px;
  border: 2px solid #dbdfe9;
  background: #eef0f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;

  :global {
    .upload-button {
      position: absolute;
      bottom: 2px;
      right: 0;
      transform: translate(50%, 50%);
      cursor: pointer;

      img {
        box-shadow: 0px 2px 10px 0px rgba(76, 78, 100, 0.22);
        border-radius: 50%;
      }

      .ant-upload-list {
        display: none;
      }
    }
  }
}

.FormComponent {
  :global {
    .ant-select-lg {
      height: 37px;

      .ant-select-selector {
        font-size: 0.875rem;
      }
    }

    .width95 {
      width: 95%;
    }

    .width50 {
      width: 50%;
    }

    .MoreWidth {
      .ant-form-item {
        width: 95%;
      }
    }

    .ledgerField {
      .closeIcon {
        opacity: 0;
        transition: 0.4s;
      }

      &:hover {
        .closeIcon {
          opacity: 1;
        }
      }
    }

    .common-input-wrapper {
      .ant-input-group-addon {
        &:first-of-type {
          border-radius: 4px 0 0 4px;
        }

        &:last-of-type {
          border-radius: 0px 4px 4px 0;

          .ant-select-selection-item {
            color: white !important;
          }
        }
      }
    }

    .creditColor {
      .ant-input-wrapper {
        @extend .common-input-wrapper;

        .ant-input-group-addon {
          &:first-of-type {
            // border: 1px solid #effcef;
            background: #effcef;
          }

          &:last-of-type {
            border: 1px solid #009933;
            background: #009933;
          }
        }
      }
    }

    .debitColor {
      .ant-input-wrapper {
        @extend .common-input-wrapper;

        .ant-input-group-addon {
          &:first-of-type {
            // border: 1px solid #fcefef;
            background: #fcefef;
          }

          &:last-of-type {
            border: 1px solid #ff4d4f;
            background: #ff4d4f;
          }
        }
      }
    }

    .ant-select-selection-item {
      color: #000000e0;
    }

    .ant-select-selector {
      border-radius: 4px !important;
    }

    .ant-input-lg {
      border-radius: 4px;
      font-size: 0.875rem;
    }

    .ant-form-item {
      margin-bottom: 0px;

      .ant-form-item-label {
        label {
          color: #a3a9b1 !important;
          font-size: 13px;
        }
      }
    }

    .titleMendetory {
      font-weight: 300;
      color: #ff4d4f;
      font-size: 0.875rem;
    }

    .titleField {
      font-weight: 300;
      font-size: 0.875rem;
      color: rgb(73, 73, 73);
    }

    .gstNoField {
      .ant-input-group-addon {
        border-radius: 0 4px 4px 0;
        padding: 0px 6px;

        .ant-btn {
          height: 35px;
        }
      }
    }

    .address {
      width: 95%;
      .ant-form-item:first-child {
        width: 50%;
      }

      .ant-form-item:nth-child(2) {
        width: 50%;
      }
    }

    .titleLevel3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.3px;
      color: rgba(51, 51, 51, 1);
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-table-cell {
      padding: 10px !important;
    }

    .editable-cell-value-wrap {
      padding: 4px;
    }

    .addRow {
      background-color: #f1f1fa;
    }

    .ant-btn {
      padding: 0px;
      height: 37px;
    }
  }
}

.addButton {
  background-color: rgba(27, 132, 255, 0.1);
  color: rgba(27, 132, 255, 1);
  border-radius: 4px;

  &:hover {
    background-color: rgba(27, 132, 255, 0.2) !important;
    color: rgba(27, 132, 255, 1) !important;
    box-shadow: none;
  }
}

.uploadCard {
  border: 1px solid rgba(219, 223, 233, 1);
  border-radius: 4px;

  :global {
    .card-header {
      padding: 16px 24px;
      border-bottom: 1px solid rgba(219, 223, 233, 1);

      .card-title {
        font-size: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 0px;
      }
    }

    .card-body {
      .space-wrapper {
        padding: 24px;

        .ant-form-item {
          margin: 0px;
        }
      }
    }

    .card-footer {
      border-top: 1px solid rgba(219, 223, 233, 1);
      padding: 16px 24px;
    }
  }
}

.contactList {
  :global {
    .contact-list-header {
      display: grid;
      grid-template-columns: 1fr minmax(230px, 230px) minmax(60px, 60px);
      padding: 0px 16px 0px 24px;

      div {
        font-size: 14px;
        letter-spacing: 0.15px;
        color: rgba(163, 169, 177, 1);
      }
    }

    .contact-list-item {
      display: grid;
      grid-template-columns: 1fr minmax(230px, 230px) minmax(60px, 60px);
      padding: 0px 16px 0px 24px;

      & > div {
        padding: 8px 0px;
        font-size: 14px;
        letter-spacing: 0.15px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(51, 51, 51, 1);

        &:first-child {
          font-weight: 500;
        }

        button {
          padding: 0px;
          height: auto;
          width: auto;
        }
      }
    }
  }
}

.ContactPerson {
  padding: 1.25rem 1.25rem 0 1.25rem;

  :global {
    .ant-form-item {
      margin-bottom: 0px;
    }

    .error-message {
      color: #ff4d4f;
      font-size: 14px;
    }

    .contact-list-header {
      display: grid;
      grid-template-columns: 1fr minmax(180px, 180px) minmax(160px, 160px) minmax(60px, 60px);
      padding: 0px 16px 0px 24px;

      div {
        font-size: 14px;
        letter-spacing: 0.15px;
        color: rgba(163, 169, 177, 1);
      }
    }

    .contact-list-item {
      display: grid;
      grid-template-columns: 1fr minmax(180px, 180px) minmax(160px, 160px) minmax(60px, 60px);
      padding: 0px 16px 0px 24px;

      & > div {
        padding: 8px 0px;
        font-size: 14px;
        letter-spacing: 0.15px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(51, 51, 51, 1);

        &:first-child {
          font-weight: 500;
        }

        button {
          padding: 0px;
          height: auto;
          width: auto;
        }
      }
    }
  }
}

.ContactPersonpopover {
  :global {
    .ant-popover-inner {
      padding: 6px 12px;

      .header {
        border-bottom: 1px solid #80808045;
        margin: 10px 0;
        padding-bottom: 2px;
      }

      .title {
        font-weight: 300;
        color: #5c5c5ce0;
      }

      .ant-input {
        padding: 4px 8px;
        line-height: 1;
        border-radius: 4px;
      }

      .savebtn {
        line-height: 1;
        padding: 7px 12px;
        background-color: #30bfee;
        box-shadow: none;
        color: white;
      }

      .addMorebtn {
        padding: 7px 0px;
        font-size: 0.825rem;
        box-shadow: none;
        color: #30bfee;
      }
    }
  }
}

.contactPersonCard {
  overflow-x: auto;

  :global {
    .ant-card,
    .ant-card-body {
      padding: 0px;
    }

    .ant-card-bordered {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      padding: 6px;
      width: fit-content;
    }

    .name {
      color: #494949;
      font-weight: 300;
    }

    .otherDetail {
      font-weight: 300;
      color: #6e6e6e;
    }

    .edit {
      color: #30bfee;
      margin-top: 5px;
      font-size: 0.85rem;
      text-decoration: underline !important;
    }
  }
}

.fileUpload {
  :global {
    .uploadBtn {
      border: 2px dotted #d9d9d9;
      box-shadow: none;
      padding: 0 10px;

      &:hover {
        border: 2px dotted #d9d9d9 !important;
      }
    }

    .files {
      border: none;
      padding: 0 8px;
    }
  }
}

.listPopover {
  :global {
    .ant-popover-inner {
      padding: 8px;
      box-shadow: 0 0px 16px 0 rgba(0, 0, 0, 0.26), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
    }

    .fileList > div:not(:last-child) {
      border-bottom: 1px solid #d9d9d9;
    }
  }
}
