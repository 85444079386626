.ant-form-item .ant-form-item-explain-erro {
    padding-left: 30px !important;
}

.taskCollaps {
    font-size: 14px;
    border-color: #dbdfe9;

    :global {
        .task-header {
            color: #fff !important;
            font-weight: 600;
            padding: 12px 16px;
        }

        .ant-scroll-number-only-unit {
            color: white !important;
            font-size: 12px !important;
            font-weight: 400 !important;
            line-height: auto !important;
        }

        .length-count {
            height: 24px;
            width: 24px;
            display: inline-flex;
            border: 1px solid #dbdfe979;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            font-size: 12px;
            background-color: #2fc8f67d;
            color: #fff;
            line-height: 1;
        }

        .ant-list {
            border: none;

            .ant-spin-nested-loading {
                border-radius: 10px;
                background-color: #fff;
                overflow: hidden;
            }

            .ant-list-item {
                border-block-end: transparent;
                padding: 0px;
                border-bottom: 1px solid #dbdfe9;
                background-color: #fff;

                &:last-child {
                    border-bottom: none;
                }

                .task-grid-wrapper {
                    width: 100%;
                    display: grid;
                    align-items: stretch;

                    * {
                        color: #535c69;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1;
                        letter-spacing: 0.18451327085494995px;
                        align-items: center;
                        display: inline-flex;
                    }

                    .ant-picker-clear {
                        display: none;
                    }

                    &.header {
                        span {
                            color: #6f7994a3;
                        }

                        svg {
                            fill: #6f7994a3;
                        }
                    }

                    .list-item-title {
                        cursor: pointer;
                        display: inline-flex;
                        gap: 5px;

                        &.unread {
                            font-weight: 600;
                            color: #50576a;
                        }

                        &.name:hover {
                            text-decoration: underline;
                        }
                    }

                    &>div {
                        display: flex;
                        align-items: center;
                        padding: 11px 16px;
                        height: 100%;
                        width: 100%;
                    }

                    .ant-avatar-string {
                        color: #ffffffd6;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 2;
                    }

                    .accept-btn {
                        // background-color: #2fc6f6;

                        span {
                            color: #fff;
                        }
                    }

                    &>div {
                        border-left: 1px solid #dbdfe9;

                        &:first-child {
                            border-left: none;
                        }
                    }

                    .assigneebg {
                        background-color: #d9f3fa85 !important;
                        height: 100%;
                        width: 100%;
                        border-left: 1px solid #dbdfe9;
                    }

                    .statusbg {
                        // background-color: #c2ef3c57 !important;
                        background-color: #8080800f;
                        height: 100%;
                        width: 100%;
                        border-left: 1px solid #dbdfe9;
                    }

                    .ant-select-arrow {
                        opacity: 0;
                        transition: opacity 0.5s;
                    }

                    .ant-select-selector {
                        // border: 1px solid transparent;
                        transition: opacity 0.5s;
                        padding: 0px;
                    }

                    .status {
                        .ant-select-selection-item {
                            border-radius: 20px;
                            padding: 5px 10px;
                            font-size: 11px;
                            font-weight: 400;
                        }

                        .status-new .ant-select-selection-item {
                            background-color: rgba(124, 0, 254, 0.1);
                            color: #7c00fe;
                        }

                        .status-proposal-sent .ant-select-selection-item {
                            background-color: rgba(0, 123, 255, 0.1);
                            color: #007bff;
                        }

                        .status-follow-up .ant-select-selection-item {
                            background-color: rgba(11, 97, 197, 0.1);
                            color: #0b61c5;
                        }

                        .status-waiting-for-response .ant-select-selection-item {
                            background-color: rgba(145, 79, 30, 0.1);
                            color: #914f1e;
                        }

                        .status-confirmed .ant-select-selection-item {
                            background-color: rgba(56, 127, 57, 0.1);
                            color: #387f39;
                        }

                        .status-booking-in-progress .ant-select-selection-item {
                            background-color: rgba(245, 0, 79, 0.1);
                            color: #f5004f;
                        }

                        .status-payment .ant-select-selection-item {
                            background-color: rgba(55, 58, 64, 0.1);
                            color: #373a40;
                        }

                        .status-document-missing .ant-select-selection-item {
                            background-color: rgba(249, 115, 0, 0.1);
                            color: #f97300;
                        }

                        .status-in-process .ant-select-selection-item {
                            background-color: rgba(133, 15, 141, 0.1);
                            color: #850f8d;
                        }
                    }

                    .deleteStatus {
                        span {
                            padding: 5px 10px;
                            border-radius: 20px;
                            font-size: 11px;
                            font-weight: 400;
                        }

                        .status-new {
                            background-color: rgba(124, 0, 254, 0.1);
                            color: #7c00fe;
                        }

                        .status-proposal-sent {
                            background-color: rgba(0, 123, 255, 0.1);
                            color: #007bff;
                        }

                        .status-follow-up {
                            background-color: rgba(56, 127, 57, 0.1);
                            color: #387f39;
                        }

                        .status-waiting-for-response {
                            background-color: rgba(145, 79, 30, 0.1);
                            color: #914f1e;
                        }

                        .status-confirmed {
                            background-color: rgba(56, 127, 57, 0.1);
                            color: #387f39;
                        }

                        .status-booking-in-progress {
                            background-color: rgba(245, 0, 79, 0.1);
                            color: #f5004f;
                        }

                        .status-payment {
                            background-color: rgba(55, 58, 64, 0.1);
                            color: #373a40;
                        }

                        .status-document-missing {
                            background-color: rgba(249, 115, 0, 0.1);
                            color: #f97300;
                        }

                        .status-in-process {
                            background-color: rgba(133, 15, 141, 0.1);
                            color: #850f8d;
                        }
                    }
                }

                .ant-radio-wrapper {
                    margin-right: 16px;
                }

                .category-icon {
                    height: 26px;
                    width: 26px;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.red {
                        background-color: rgb(248 40 90 / 10%);

                        &.curruncy {
                            svg path {
                                stroke: #f8285a;
                            }
                        }
                    }

                    &.green {
                        background-color: rgb(16 193 77 / 10%);

                        &.curruncy {
                            svg path {
                                stroke: #10c14d;
                            }
                        }
                    }

                    &.gray {
                        background-color: rgba(111, 121, 148, 0.1);

                        &.curruncy {
                            svg path {
                                stroke: #6f7994;
                            }
                        }
                    }
                }
            }
        }

        .stepper {
            &> :nth-last-child(2) {
                .ant-steps-item-tail {
                    &::after {
                        width: 98% !important;
                    }
                }
            }

            .ant-steps-item {
                width: 45px;

                .ant-steps-item-tail {
                    padding: 0px;
                }
            }

            .ant-steps-item-finish,
            .ant-steps-item-process {
                .ant-steps-item-icon {
                    background-color: #099830 !important;
                    border: none;
                    height: 16px;
                    width: 16px;
                    display: flex;
                    justify-content: center;

                    .ant-steps-icon {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            .ant-steps-item-wait {
                .ant-steps-item-icon {
                    background-color: #c6cdd3;
                    width: 10px;
                    height: 10px;
                    margin-top: 0px;

                    .ant-steps-icon {
                        display: none;
                    }
                }
            }

            .ant-steps-item-active {
                .ant-steps-item-icon {
                    position: relative;
                    background-color: #525c69;

                    &::after {
                        content: "";
                        background-color: white;
                        height: 5px;
                        width: 5px;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    .ant-steps-icon {
                        display: none;
                    }
                }
            }

            .ant-steps-item-icon {
                position: relative;
                z-index: 1;
            }

            .ant-steps-item-tail {
                margin-inline-start: 45px;
                top: 7px !important;

                &::after {
                    height: 2px !important;
                }
            }

            .ant-steps-item-finish {
                .ant-steps-item-tail::after {
                    background-color: #099830 !important;
                }
            }

            .ant-steps-item-active,
            .ant-steps-item-wait {
                .ant-steps-item-tail::after {
                    background-color: #c6cdd3 !important;
                }
            }

            .ant-steps-item-wait {
                .ant-steps-item-tail {
                    top: 5px !important;
                }
            }

            .ant-steps-item-finish,
            .ant-steps-item-process,
            .ant-steps-item-active,
            .ant-steps-item-wait {
                .ant-steps-item-title {
                    color: #757575 !important;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

.taskDatePicker {
    padding-right: 7px;
    padding-left: 7px;

    &.overDue {
        :global {
            .ant-picker-input {
                input {
                    color: #f8285a !important;
                }
            }
        }
    }

    &.toDay {
        :global {
            .ant-picker-input {
                input {
                    color: #2b952b !important;
                }
            }
        }
    }

    :global {

        .ant-picker-clear,
        .ant-picker-suffix {
            display: none;
        }
    }
}

.badge {
    :global {
        .ant-badge {
            opacity: 0 !important;
            transition: all 0.2s;
        }
    }

    &:hover {
        :global {
            .ant-badge {
                opacity: 1 !important;
            }
        }
    }
}

.commentWrapper {
    padding: 20px 24px 11px 11px;

    :global {
        .comment-list {
            display: flex;
            padding: 10px 15px;

            .comment-text-section {
                display: flex;
                background-color: #edf1f3;
                flex-direction: column;
                border-radius: 23px;
                padding: 7px 21px 7px 15px;

                .user-name {
                    transition: border-bottom-color 0.3s linear;
                    font-weight: 700;
                    color: #2067b0;
                }

                .comment-time {
                    font-size: 9px;
                    color: #bcc2c7;
                    font-weight: 500;
                }

                .mentionHighlight {
                    color: #003f7a;
                    font-weight: bold;
                }
            }

            .comment-reply {
                padding-left: 10px;
                color: #a8aeb5;
                display: flex;
                gap: 8px;
                font-size: 12px;

                .action-btn {
                    cursor: pointer;
                }
            }
        }

        .send-message-wrapper {
            padding-left: 15px;

            .avatar {
                height: 36px;
                width: 36px;
                border-radius: 50%;
            }

            .text-area {
                width: 70%;
                margin-left: 8px;
            }

            .mentionHighlight .ant-mentions {
                font-weight: bold;
                color: #1890ff;
            }

            .ant-mentions-outlined {
                border-radius: 20px;
            }

            .ant-mentions-outlined:focus {
                border-radius: 20px;
            }

            .ant-mentions>textarea {
                padding: 8px 11px;
            }

            .mentions-container {
                overflow: hidden;
                transition: height 0.3s ease;

                .ant-mentions-outlined {
                    &:focus-within {
                        box-shadow: none;
                    }
                }
            }

            .action-buttons {

                .send,
                .cancel {
                    padding: 7px 16px;

                    span {
                        font-weight: 600;
                        font-size: 12px;
                    }
                }

                .send {
                    background-color: #3bc8f5;

                    span {
                        color: white;
                    }

                    &:hover {
                        background-color: #3eddff;
                    }
                }

                .cancel {
                    box-shadow: none;
                    transition: 0.3s all;

                    span {
                        color: #525c69;
                    }

                    &:hover span {
                        color: #818181;
                    }
                }
            }
        }
    }
}