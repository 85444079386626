.pageTitle {
  font-weight: 300 !important;
  margin-bottom: 0px !important;
  color: #ffffff !important;
  font-size: 26px !important;
  letter-spacing: 0.3px !important;
  line-height: 20px !important;
  white-space: nowrap;
}

.mytaskBlock {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.customFilterBar {
  :global {
    .ant-space-item:nth-child(2) {
      width: 100%;
    }
  }
}

.createBtn {
  border-radius: 6px;
  background-color: #bbed21;
  font-size: 14px;
  font-weight: 700;
  border: none;
  color: rgba(111, 121, 148, 1);
  padding: 19px 20px;
  &:hover {
    background-color: #a5d612 !important;
    color: rgba(111, 121, 148, 1) !important;
  }
}

.saveBtn {
  border-radius: 6px;
  background-color: #30bfee;
  font-size: 14px;
  font-weight: 700;
  border: none;
  color: white;
  padding: 19px 20px;
  &:hover {
    background-color: #30bfee !important;
    color: white !important;
  }
}

.cancelBtn {
  border-radius: 6px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid rgb(223, 225, 229);
  color: rgb(95, 97, 122);
  padding: 19px 20px;
  &:hover {
    background-color: transparent;
    color: #4096ff !important;
    border: 1px solid #4096ff !important;
  }
}

.addNew {
  padding: 18px 7px;
  border: 1px solid #1a73e8;
  transition: 0.3s all;
  background-color: transparent;
  span {
    color: #1a73e8;
    font-weight: 600;
  }
  &:hover {
    background-color: transparent !important;
    border: 1px solid #4096ff !important;
    span{
      color: #4096ff;
    }
  }
}

.primaryBtn {
  padding: 19px 15px;
  border: 1px solid #1a73e8;
  transition: 0.3s all;
  background-color: #1a73e8;
  span {
    color: #fff;
    font-weight: 600;
  }
  &:hover {
    border-color: #4096ff;
    background-color: #4096ff !important;
  }
}

.inquireyBtn {
  border-radius: 6px;
  background-color: #bbed21;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  font-weight: 700;
  letter-spacing: 0.3px;
  border: none;

  :global {
    span {
      color: #6f7994;
    }
    .ant-btn-default {
      background-color: transparent;
      box-shadow: none;
    }
    .ant-btn {
      border: none;
      font-weight: 500;
      padding: 8px 17.25px 8px 17px !important;
    }
    button {
      height: auto;
      position: relative;
      &:first-child:before {
        content: "";
        width: 1px;
        position: absolute;
        background-color: #6f7994;
        top: 9px;
        bottom: 9px;
        right: 0px;
      }
      svg {
        fill: #6f7994;
      }
      &:active {
        background-color: #bbed21 !important;
      }
      &:focus-visible {
        outline: none !important;
      }
    }
  }

  @media (max-width: 1550px) and (min-width: 1200px) {
    :global {
      .ant-btn {
        padding: 6px 15px !important;
      }
    }
  }
}

.searchInput {
  border: none;
  border-radius: 6px;
  height: auto;
  max-width: 700px;

  :global {
    .ant-input {
      border-right: 1px solid transparent;
      padding: 7px 11px;
      border-color: transparent;

      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      background-color: white;
      border-color: transparent;

      svg {
        fill: #b1b6bb;
      }
    }
  }

  @media (max-width: 1550px) and (min-width: 1200px) {
    :global {
      .ant-input {
        padding: 5px 11px;
      }
    }
  }
}

.noteCreate {
  @extend .inquireyBtn;

  :global {
    .ant-btn {
      border: none;
      font-weight: 500;
      padding: 5px 12px 5px 12px !important;
    }
  }

  @media (max-width: 1550px) and (min-width: 1200px) {
    :global {
      .ant-btn {
        padding: 6px 15px !important;
      }
    }
  }
}

.defaultBtn {
  height: auto;
  padding: 10px;

  @media (max-width: 1550px) and (min-width: 1200px) {
    padding: 8px;
  }
}

.closeLabel {
  padding: 7px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  background-color: #30bfee;
  position: absolute;
  top: 18px;
  left: 0px;
  transform: translateX(-100%);
  cursor: pointer;
  transition: 300ms all;

  :global {
    .anticon {
      padding: 3px;
      border: 2px solid transparent;
      transition: all 0.3s;

      svg {
        fill: #fff;
      }
    }

    span {
      padding-left: 5px;
      font-weight: 400;
      color: #fff;
      font-size: 11px;
    }
  }

  &:hover {
    background-color: #eb413b;
    :global {
      .anticon {
        border: 2px solid rgba(255, 240, 240, 0.973);
        border-radius: 50%;
      }
    }
  }
}

.taskDrawer {
  // background-color: #eef2f4 !important;
  padding-right: 50px;
  position: relative;
  overflow: visible !important;

  :global {
    .close-label {
      padding: 7px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background-color: #1a73e8;
      position: absolute;
      top: 18px;
      left: 0px;
      transform: translateX(-100%);
      cursor: pointer;

      .anticon {
        padding: 3px;
        border: 2px solid transparent;
        transition: all 0.3s;

        svg {
          fill: #fff;
        }
      }

      span {
        padding-left: 5px;
        font-weight: 400;
        color: #fff;
        font-size: 11px;
      }

      &:hover {
        background-color: #eb413b;
        .anticon {
          border: 2px solid rgba(255, 240, 240, 0.973);
          border-radius: 50%;
        }
      }
    }

    .minimize-label {
      padding: 7px;
      padding-left: 15px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background-color: #d9dcdf;
      position: absolute;
      top: 73px;
      left: 0px;
      transform: translateX(-100%);
      cursor: pointer;
      color: #fff;
      display: inline-flex;

      svg {
        fill: #525c69;
        opacity: 0.5;
        transition: all 0.5s;
      }

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }

    .sync-label {
      padding: 10px;
      padding-left: 15px;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      background-color: #d9dcdf;
      position: absolute;
      top: 138px;
      left: 0px;
      transform: translateX(-100%);
      color: #525c69;
      font-size: 13px;
      transition: all 0.4s;

      div {
        font-weight: 400;
        line-height: 1;
      }

      &.saved {
        background-color: #52c41a;
      }
    }

    .drawer-header {
      padding: 23px 0px 23px 12px;

      // .ant-tag.ant-tag-orange {
      //   font-size: 14px;
      //   padding: 5px 6px;
      //   line-height: 1;
      // }
    }

    .ant-drawer-body {
      padding: 0px 15px 15px;
    }

    .drawer-title {
      font-size: 26px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.3px;
      color: rgb(51, 51, 51);
      margin-right: 20px;
    }
  }
}

.commonStepper {
  width: 70%;
  & > :nth-last-child(2) {
    :global {
      .ant-steps-item-tail {
        &::after {
          width: 98% !important;
        }
      }
    }
  }
  :global {
    .ant-steps-item {
      .ant-steps-item-tail {
        padding: 0px;
      }
    }
    .ant-steps-item-finish,
    .ant-steps-item-process {
      .ant-steps-item-icon {
        background-color: #099830 !important;
        border: none;
        height: 30px;
        width: 30px;
        .ant-steps-icon {
          .anticon {
            svg {
              margin-top: 6px;
              fill: white;
              height: 18px;
              width: 18px;
              path {
                fill: white;
                filter: drop-shadow(10px 10px 0 rgb(255, 255, 255)) drop-shadow(-10px -10px 0 rgb(255, 255, 255));
              }
            }
          }
        }
      }
    }
    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background-color: #c6cdd3;
        width: 10px;
        height: 10px;
        margin-top: 10px;

        .ant-steps-icon {
          display: none;
        }
      }
    }
    .ant-steps-item-process {
      .ant-steps-item-icon {
        position: relative;
        background-color: #525c69;
        &::after {
          content: "";
          background-color: white;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        // &::before {
        //     content: "";
        //     position: absolute;
        //     bottom: -100%;
        //     left: 50%;
        //     transform: translate(-50%, 300%);
        //     width: 11px;
        //     height: 11px;
        //     background-color: #30bfee;
        //     border-radius: 50%;
        // }
        .ant-steps-icon {
          display: none;
        }
      }
      // &::before {
      //     content: "";
      //     position: absolute;
      //     bottom: -45px;
      //     left: 18px;
      //     width: 72px;
      //     height: 72px;
      //     background-color: #f6f6f6;
      //     border-radius: 50%;
      // }
    }
    .ant-steps-item-active {
      .ant-steps-item-icon {
        position: relative;
        background-color: #525c69;
        // &::after {
        //     content: "";
        //     background-color: white;
        //     height: 10px;
        //     width: 10px;
        //     border-radius: 50%;
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     transform: translate(-50%, -50%);
        // }
        &::before {
          content: "";
          position: absolute;
          bottom: -100%;
          left: 50%;
          transform: translate(-50%, 300%);
          width: 11px;
          height: 11px;
          background-color: #099830;
          border-radius: 50%;
        }
        // .ant-steps-icon {
        //     display: none;
        // }
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -45px;
        left: 18px;
        width: 72px;
        height: 72px;
        background-color: #f6f6f6;
        border-radius: 50%;
      }
    }
    .ant-steps-item-icon {
      position: relative;
      z-index: 1;
    }
    .ant-steps-item-tail {
      top: 13px !important;
      &::after {
        height: 3px !important;
      }
    }
    .ant-steps-item-finish {
      .ant-steps-item-tail::after {
        background-color: #099830 !important;
      }
    }
    // .ant-steps-item-active,
    .ant-steps-item-wait,
    .ant-steps-item-process {
      .ant-steps-item-tail::after {
        background-color: #c6cdd3 !important;
      }
    }
    .ant-steps-item-finish,
    .ant-steps-item-process,
    .ant-steps-item-active,
    .ant-steps-item-wait {
      .ant-steps-item-title {
        color: #757575 !important;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
