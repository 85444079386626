.image-gallery {
    width: 100%;
    max-width: 800px;
    margin: auto;

    .slick-slide>div {
        display: block;
    }

    .slick-slider {
        margin-bottom: 30px;
    }

    .image-slide {
        display: block !important;

        .ant-image {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 8px;
        }
    }

    .slick-list {
        border-radius: 8px;

        .slick-track {
            display: flex;
            align-items: center;
            border-radius: 8px;
            background-color: rgb(214, 214, 214);
        }
    }

    .slick-next {
        right: 0 !important;
    }

    .slick-prev {
        left: 0 !important;
        z-index: 100;
    }

    .image-skeleton {
        display: block;

        .image-skeleton {
            width: 100%;
        }
    }
}