.sidebar {
    position: fixed !important;
    right: 0;
    top: 0;
    z-index: 1001;

    .rightSidebar {
        padding: 15px 8px;
        border-left: 1px solid hsla(0, 0%, 100%, 0.094);
        flex-direction: column;
        height: 100vh;
        transition: all .5s;

        :global {
            button {
                padding: 15px 14px;
                height: auto;
                width: auto;
                padding: 6px;
                background-color: #F5F6FA1A;
                border-radius: 50%;

                &:hover {
                    background-color: #f5f6fa38 !important;
                }

                &:first-child {
                    background-color: #2FC6F6;
                    padding: 4px;
                    border-radius: 6px;

                    &:hover {
                        background-color: #2fc8f68c !important;
                    }
                }
            }

            .anticon svg {
                fill: #fff;
            }
        }

        &.light {
            border-left: 1px solid #dbe4e8 !important;

            button {
                background-color: #DFE3E7;

                &:hover {
                    background-color: #dddddd !important;
                }

                &:first-child {
                    background-color: #DFE3E7;

                    &:hover {
                        background-color: #dddddd !important;
                    }
                }

                svg {
                    fill: #616B77;
                }
            }

            
        }
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: hsla(0, 0%, 100%, 0.094) !important;
        transition: all 1s;

        &.dividerLight {
            height: 1px;
            width: 100%;
            background-color: #dbe4e8 !important;
        }
    }
}