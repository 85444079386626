.customNavTab {
    padding: 0px 15px;
    background-color: #ffffff14;
    border-radius: 20px;

    :global {
        .ant-tabs-nav {
            margin-bottom: 0px;

            .dividerLeft {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: -15px;
                    width: 1px;
                    height: 100%;
                    background-color: #ffffff4d;
                }
            }

            &::before {
                content: unset;
            }

            .ant-tabs-nav-list {
                .ant-tabs-ink-bar {
                    background: #fff;
                }

                .ant-tabs-tab {
                    padding: 10px;
                    margin-left: 10px;

                    .ant-tabs-tab-btn {
                        color: #cacaca;
                        font-weight: 400;
                        font-size: 15px;
                    }

                    &:first-child {
                        margin-left: 0px;
                    }

                    &.ant-tabs-tab-active .ant-tabs-tab-btn {
                        color: #fff;
                    }

                    .ant-tabs-tab-btn {
                        line-height: 1;
                    }

                    .ant-tabs-tab-btn:hover {
                        color: white;
                    }
                }
            }
        }
    }

    @media (max-width: 1550px) and (min-width: 1200px) {
        padding: 0px 13px;

        :global {
            .ant-tabs-nav {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        padding: 8px;
                        margin-left: 8px;

                        .ant-tabs-tab-btn {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
