blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}
a {
  text-decoration: none;
}
menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
// default CSS
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.inline-block {
  display: inline-block;
}
.items-center {
  align-items: center;
}
.justify-end {
  justify-content: end;
}
.inline-flex {
  display: inline-flex;
}
.relative {
  position: relative;
}
.justify-center {
  justify-content: center;
}
.flex-col {
  flex-direction: column;
}
.block {
  display: block;
}
.m-auto {
  margin: auto;
}
.w-full {
  width: 100%;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-right {
  text-align: right;
}

.justify-between {
  justify-content: space-between;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.underline {
  text-decoration-line: underline;
}

.mb-0 {
  margin-bottom: 0rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}

.mr-0 {
  margin-right: 0rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}

.mt-0 {
  margin-top: 0rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}

.m-0 {
  margin: 0rem;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}

.pb-0 {
  padding-bottom: 0rem;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-7 {
  padding-bottom: 1.75rem;
}

.pr-0 {
  padding-right: 0rem;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-7 {
  padding-right: 1.75rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-9 {
  padding-right: 2.25rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-12 {
  padding-right: 3rem;
}

.pl-0 {
  padding-left: 0rem;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}

.pt-0 {
  padding-top: 0rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-7 {
  padding-top: 1.75rem;
}

.p-0 {
  padding: 0rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}

.gap-0 {
  gap: 0px;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}

.overflow-auto {
  overflow: auto;
}

.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

.border {
  border: 1px solid;
}

.rounded-full {
  border-radius: 9999px;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.leading-3 {
  line-height: 0.75rem;
}

.cursor-pointer {
  cursor: pointer;
}
.color-white {
  color: white;
}

.ant-btn:focus-visible {
  outline: none !important;
}

@media screen and (max-width: 640px) {
  .max-sm-w-full {
    width: 100%;
  }
  .max-sm-mb-2 {
    margin-bottom: 0.5rem;
  }
  .max-sm-pt-4 {
    padding-top: 1rem;
  }
  .max-sm-hidden {
    display: none;
  }
  .max-sm-block {
    display: block;
  }
}
@media not all and (min-width: 768px) {
  .max-md-mb-3 {
    margin-bottom: 0.75rem;
  }
  .max-md-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .max-md-mr-0 {
    margin-right: 0;
  }
  .max-md-hidden {
    display: none;
  }

  .max-md-block {
    display: block;
  }
}
