.loginPage {
    :global {
        .forget {
            text-decoration: underline;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                color: blue;
            }
        }
    }
}