.calculator {
    :global {
        .fields {
            width: auto;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            border-radius: 15px;
            background-color: white;
            backdrop-filter: drop-shadow(2px 4px 6px black);
            z-index: 1;
            .ant-typography {
                color: black;
                font-size: 16px;
                font-weight: 600;
                padding-bottom: 5px;
                text-align: start;
            }
        }
        .calculation {
            .title {
                color: black;
                font-size: 15px;
                padding-bottom: 5px;
                font-weight: 300;
                text-align: center;
            }
            .amount{
                font-size: 18px;
                font-weight: 600;
                width: 100px;
                text-align: center;
            }
            .operator{
                font-size: 18px;
                font-weight: 600;
                width: 10px;
                text-align: center;
            }
        }
    }
}
