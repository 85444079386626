.tabsLayout {
  :global {
    .custom-tab {
      .ant-tabs-nav::before {
        content: unset;
      }

      .ant-tabs-nav-wrap {
        border-radius: 10px;
        background-color: white;
        padding: 0px 30px;
      }

      .ant-tabs-tab {
        margin: 0 0 0 0;

        .ant-tabs-tab-btn {
          font-size: 15px;
          padding: 6px 18px;
          color: #6F7994;
        }
      }

      .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1B84FF !important;
      }

      .ant-tabs-ink-bar {
        background-color: #1B84FF !important;
        height: 3px;
      }

      .ant-tabs-tab-btn:hover {
        color: #1B84FF;
      }
    }
  }

  @media (max-width: 1550px) and (min-width: 1200px) {
    :global {
      .custom-tab {
        .ant-tabs-nav-wrap {
          padding: 0px 25px;
        }

        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            padding: 4px 16px;
          }
        }
      }
    }
  }
}

.commonNav {
  width: 100%;
  max-width: 420px;

  @media (max-width: 1550px) and (min-width: 1200px) {
    max-width: 358px !important;
  }
}

.customFilterTag {
  padding: 0px 15px;
  background-color: #FFFFFF14;
  border-radius: 20px;

  :global {
    .label {
      &.devider-left::before {
        content: "";
        position: absolute;
        top: 7px;
        bottom: 7px;
        left: -10px;
        width: 1px;
        background-color: #ffffff4d;
      }
    }

    svg {
      display: none;
    }

    .ant-space-item {
      display: inline-flex;
    }

    .ant-space-item>span {
      color: #fff;
      padding: 7px 10px;
      line-height: 1;
      font-size: 15px;
      position: relative;

      &.ant-tag {
        border-radius: 20px;

        &:hover {
          background-color: #FFFFFF1A;
          color: #fff;
        }

        &:active {
          background-color: transparent;
          color: #fff;
        }

        // span {
        //     background-color: #FFFFFF33;
        //     padding: 5px;
        //     border-radius: 100px;
        //     display: inline-block;
        // }
      }

      &.ant-tag-checkable-checked {
        background-color: #FFFFFF1A;
      }
    }
  }

  @media (max-width:1350px) {
    :global {
      .hideText {
        display: none;
      }

      .showIcon {
        display: inline-flex;

        &::before {
          content: '';
          margin-left: -20px;
          margin-right: 15px;
          width: 1px;
          background-color: #ffffff4d;
        }

        svg {
          fill: white;
          font-size: 17px;
          display: inline-block;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 1550px) and (min-width: 1200px) {
    padding: 0px 13px;

    :global {
      .ant-space-item>span {
        padding: 4px 8px;
        font-size: 14px;
      }

      .label {
        &.devider-left::before {
          top: 4px;
          bottom: 4px;
        }
      }
    }
  }
}