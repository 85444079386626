.sidebar {
    :global {
        .ant-layout-sider {
            background-color: aqua;
            border-inline-end: 1px solid rgba(255, 255, 255, 0.08);
            position: fixed !important;
            top: 0;
            left: 0;
            min-width: 234px !important;
            padding: 0 10px;

            .logoText {
                font-size: 18px;
                font-weight: 600;
                color: white;
                overflow: hidden;
                white-space: nowrap;
            }

            .paddingCollapsed {
                padding: 15px 4.5px;
            }
            
            .paddingExpanded {
                padding: 15px 20px 15px 19px;
            }

            .logoText.hide {
                opacity: 0;
                width: 0;
            }

            .logoText.show {
                opacity: 1;
                width: auto;
                margin-left: 8px;
            }

            .sidebarMenu {
                height: calc(100vh - 66px);
                overflow-y: auto;
                scrollbar-width: none;
            }

            .ant-menu-item,
            .ant-menu-submenu-title {
                padding: 2px !important;
                padding-left: 15px !important;
            }

            .ant-menu-sub.ant-menu-inline {
                margin-left: 15px !important;
                background-color: transparent !important;
            }
            .ant-menu-title-content{
                a{
                    color: white;
                }
            }
        }

        .ant-layout-sider-collapsed{
            max-width: 80px !important;
            min-width: 80px !important
        }
    }

    @media (max-width: 1550px) and (min-width: 1200px) {
        :global {
            .ant-layout-sider {
                padding: 0 5px;

                .sidebarMenu {
                    height: calc(100vh - 60px);
                }

                .logoText {
                    font-size: 16px;
                }

                .paddingCollapsed {
                    padding: 15px 4.5px;
                }
                
                .paddingExpanded {
                    padding: 15px 10px;
                }

                .ant-menu-item,
                .ant-menu-submenu-title {
                    padding: 4px !important;
                    padding-left: 10px !important;
                    font-size: 14px !important;
                }

                .ant-menu-sub.ant-menu-inline {
                    margin-left: 10px !important;
                }
            }
        }
    }

    @media (max-width: 1550px) and (min-width: 1400px) {
        :global {
            .ant-layout-sider {
                width: 200px;
                min-width: 200px !important;
                max-width: 200px !important;       
            }

            .ant-layout-sider-collapsed{
                max-width: 60px !important;
                min-width: 60px !important
            }
        }
    }   

    @media (max-width: 1400px) and (min-width: 1200px) {
        :global {
            .ant-layout-sider {
                width: 180px;
                min-width: 180px !important;
                max-width: 180px !important;       
            }

            .ant-layout-sider-collapsed{
                max-width: 60px !important;
                min-width: 60px !important
            }
        }
    }
}
