.contactLabel {
  padding: 13px 10px;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  font-size: 11px;
  &.red {
    background-color: rgba(248, 40, 90, 0.15);
    :global {
      .ant-select-selection-item {
        color: rgb(248, 40, 90);
        text-align: center;
      }
    }
  }
  &.green {
    background-color: rgba(30, 175, 168, 0.15);
    :global {
      .ant-select-selection-item {
        color: rgba(30, 175, 168, 1);
        text-align: center;
      }
    }
  }
}
.mainTab {
  :global {
    .ant-tabs-content-holder {
      display: none;
    }
    .ant-tabs-tab {
      font-size: 15px;
      color: #6f7994;
      margin: 0px !important;
      padding: 15px 10px;
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #30bfee;
        }
      }
    }
    .ant-tabs-ink-bar {
      background: #30bfee;
      height: 3px !important;
    }
  }
}

.vendorDrawer {
  :global {
    .ant-drawer-body {
      padding: 0px;
      overflow: hidden;
      overflow-y: auto;
    }
    .vendorForm {
      line-height: 1;
    }
  }
}
