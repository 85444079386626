.avatar {
  height: 80px;
  width: 80px;
  border: 2px solid #dbdfe9;
  background: #eef0f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  :global {
    .upload-button {
      position: absolute;
      bottom: 2px;
      right: 0;
      transform: translate(50%, 50%);
      cursor: pointer;
      img {
        box-shadow: 0px 2px 10px 0px rgba(76, 78, 100, 0.22);
        border-radius: 50%;
      }
      .ant-upload-list {
        display: none;
      }
    }
  }
}
.FormComponent {
  :global {
    .ant-form-item {
      margin-bottom: 0px;
      .ant-form-item-label {
        label {
          color: #a3a9b1 !important;
          font-size: 13px;
        }
      }
    }
    .titleLevel3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.3px;
      color: rgba(51, 51, 51, 1);
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0 !important;
    }

    .titleMendetory {
      color: #ff4d4f;
      font-size: 0.875rem;
    }

    .titleField {
      font-size: 0.875rem;
      color: rgb(73, 73, 73);
    }

    .uploadDoc {
      // background-color: #eef2f4;
      // margin: 10px;
      // border-radius: 4px;
      .addButton {
        background-color: #2a92b428;
        color: #303030;
        border-radius: 4px;
        &:hover {
          background-color: #2a92b428 !important;
          color: #303030 !important;
          box-shadow: none;
        }
      }

      .ant-tabs {
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #30bfee;
          }
        }
        .ant-tabs-tab:hover {
          color: #30bfee
        }
      }
    }
  }
}
.titleLevel4 {
  font-size: 16px !important;
  letter-spacing: 0.3px !important;
  color: rgba(51, 51, 51, 1) !important;
  margin-bottom: 20px !important;
  font-weight: 400 !important;
}
.titleLevel2 {
  font-size: 16px !important;
  letter-spacing: 0.3px !important;
  color: rgba(51, 51, 51, 1) !important;
  margin-bottom: 20px !important;
}

.uploadCard {
  border: 1px solid rgba(219, 223, 233, 1);
  border-radius: 4px;
  :global {
    .card-header {
      padding: 16px 24px;
      border-bottom: 1px solid rgba(219, 223, 233, 1);
      .card-title {
        font-size: 20px;
        letter-spacing: 0.25px;
        text-align: left;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 0px;
      }
    }
    .card-body {
      .space-wrapper {
        padding: 24px;
      }
    }
    .card-footer {
      border-top: 1px solid rgba(219, 223, 233, 1);
      padding: 16px 24px;
    }
  }
}
.formButton {
  background-color: rgba(26, 115, 232, 1);
  border-color: rgba(26, 115, 232, 1);
  padding: 6px 20px;
  font-size: 14px;
  color: #fff;
  line-height: 1.8;
  height: auto;
}
.cancelButton {
  background-color: #fff;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.8;
  height: auto;
  border: 1px solid rgba(223, 225, 229, 1);
  color: rgba(95, 97, 122, 1);
}
.docList {
  :global {
    .doc-list-header {
      display: grid;
      grid-template-columns: 1fr minmax(158px, 158px) minmax(60px, 60px);
      padding: 0px 16px 0px 24px;
      div {
        font-size: 14px;
        letter-spacing: 0.15px;
        color: rgba(163, 169, 177, 1);
      }
    }
    .doc-list-item {
      display: grid;
      grid-template-columns: 1fr minmax(158px, 158px) minmax(60px, 60px);
      padding: 0px 16px 0px 24px;
      & > div {
        padding: 8px 0px;
        font-size: 14px;
        letter-spacing: 0.15px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(51, 51, 51, 1);
        &:first-child {
          font-weight: 500;
        }
        button {
          padding: 0px;
          height: auto;
          width: auto;
        }
      }
    }
  }
}
