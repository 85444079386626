.customHeader {
    display: flex;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    height: auto;

    :global{
        .anticon svg{
            fill: #fff;
        }
    }
}

.container {
    padding: 0px 25px;
    width: 100%;
}

.customSearch {
    margin-left: 20px;

    :global {
        .ant-input-group-addon {
            display: none;
        }

        input::placeholder {
            color: #ffffff8f;
        }

        .ant-input-prefix {
            svg path {
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}

.clockText {
    font-size: 2.75em;
    font-weight: 300;
    letter-spacing: 0.3px;
    padding: 10px 20px;
    line-height: 1;
    border-radius: 8px;
    transition: 300ms;
    white-space: nowrap;
    margin: 0 15px;
    color: #fff;

    &:hover {
        background-color: rgba(245, 246, 250, 0.1019607843);
    }
}

.profileLink {
    font-size: 14px;
    cursor: pointer;

    :global {
        .profile-dropdown {
            .user-name {
                font-weight: 500;
                margin-right: 10px;
                color: #fff;
                white-space: nowrap;
                font-size: 15px;
            }

            .role {
                display: block;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}